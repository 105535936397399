html,
body {
  padding: 0;
  margin: 0;
  transition: all 0.1s ease-in-out;

}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Mukta";
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
select {
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -ms-appearance: none;
  outline: none;
  background: none;
}
button {
  border: none;
  outline: none;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.btn-primary-1 {
  color: white;
  background: #385d81;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btn-primary-1:hover {
  background: #385d81c4;
}
.btn-primary-2 {
  color: #385d81;
  background: white;
  font-size: 20px;
  font-weight: 600;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.btn-primary-2:hover {
  background: rgb(226, 225, 225);
}

.action-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.action-btns-container {
  margin-top: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.action-btn {
  background: #eaeef4;
  border-radius: 20%;
  width: 64px;
  height: 64px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #385d81;
  border: 2px solid #eaeef4;
  padding: 0;
}
.action-btn > img {
  width: 36px;
}
.action-btn:hover {
  background: #dce3ed;
}
.action-btn:active {
  background: #d4ddeb;
}
.action-btn > .badge {
  background: #adb6c2;
  transition: all 0.2s ease-in-out;
  padding-bottom: 4px;
  padding-top: 4px;
  font-weight: 700;
  font-size: 12px;
  color: white;
  position: absolute;
  top: -6px;
  right: -6px;
  transition: all 0.2 ease-in-out;
  border-radius: 50px;
}
.action-btn.note-mode {
  border: 2px solid #385d81;
}
.action-btn.note-mode > .badge {
  background: #385d81;
}

.number-btns-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  position: relative;
}
.number-btn {
  background: none;
  color: #385d81;
  font-size: 40px;
  width: none;
  padding: 0;
}

#menuToggle {
  display: block;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

#menuToggle a {
  text-decoration: none;
  color: #232323;

  transition: all 0.3s ease;
}

#menuToggle a:hover {
  color: #23232363;
}

#menuToggle input {
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;

  cursor: pointer;

  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */

  -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: #cdcdcd;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
    background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
}

#menuToggle span:first-child {
  transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked ~ span {
  opacity: 1;
  transform: rotate(45deg) translate(-2px, -1px);
  background: #232323;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked ~ span:nth-last-child(3) {
  opacity: 0;
  transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked ~ span:nth-last-child(2) {
  transform: rotate(-45deg) translate(0, -1px);
}

/*
  * Make this absolute positioned
  * at the top left of the screen
  */
#menu {
  position: absolute;
  width: 300px;
  left: 0;
  padding: 20px;
  opacity: 0;
  pointer-events: none;
  background: white;
  box-shadow: 0 5px 20px 0 rgb(0 0 0 / 20%);
  list-style-type: none;
  -webkit-font-smoothing: antialiased;
  /* to stop flickering of text in safari */

  transform-origin: 0% 0%;

  transition: all 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menu li {
  padding: 10px 0;
  font-size: 22px;
}

/*
  * And let's slide it in from the left
  */
#menuToggle input:checked ~ ul {
  pointer-events: all;
  opacity: 1;
}

.timer-pause {
  width: 25px;
  height: 25px;
  margin: 0 0 0 10px;
  padding: 0;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background: #eaeef4;
  outline: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.timer-pause > svg {
  width: 50%;
}

.board-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.board-overlay > svg,
.board-overlay > img {
  position: relative;
  top: 50%;
  left: 50%;
  width: 60px;
  height: 60px;
  margin: 0 auto;
  transform: translate(-50%, -50%);
}
.sudoku-board-grid {
  border: 1px solid #344861;
}
.font-size-24-19-30-44 {
  font-size: 24px;
}
.main-header {
  border-bottom: 1px solid rgba(0, 63, 123, 0.1);
  height: 52px;
  padding: 15px 15px 10px;
}

@media screen and (orientation: landscape) {
  .main-header {
    height: 40px;
    padding: 0 15px 0;
  }
  .font-size-24-19-30-44 {
    font-size: 19px;
  }
  .sudoku-board-grid {
    border: 2px solid #344861;
  }
  .number-btns-container {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 8px;
    flex: 1;
  }
  .number-btn {
    background: #eaeef4;
    border-radius: 4px;
    color: #385d81;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .number-btn:hover {
    background: #dce3ed;
  }
  .number-btn:active {
    background: #d4ddeb;
  }
  .action-btn {
    width: 48px;
    height: 48px;
  }
  .action-btn > img {
    width: 36px;
  }
  .btn-primary-1 {
    height: 36px;
    font-size: 16px;
  }
}
@media screen and (max-width: 576px){
  body.dark{
    .number-btn{
      background-color: transparent;
      color: white;
    }
    
  }
}
@media screen and (min-width: 576px) {
  body.dark{
    .number-btn{
      background-color: #192b3c;
      color: white;
    }
    .number-btn:hover {
      background: #385d81af;
    }
    .number-btn:active {
      background: #2b4864;
    }
  }
  .main-header {
    height: 40px;
    padding: 0 15px 0;
  }
  .font-size-24-19-30-44 {
    font-size: 19px;
  }
  .sudoku-board-grid {
    border: 2px solid #344861;
  }
  .number-btns-container {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 8px;
    flex: 1;
  }
  .number-btn {
    background: #eaeef4;
    border-radius: 4px;
    color: #385d81;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .number-btn:hover {
    background: #dce3ed;
  }
  .number-btn:active {
    background: #d4ddeb;
  }
  .action-btn {
    width: 48px;
    height: 48px;
  }
  .action-btn > img {
    width: 36px;
  }
  .btn-primary-1 {
    height: 36px;
    font-size: 16px;
  }
}
@media screen and (min-width: 768px) {
  .btn-primary-1 {
    font-size: 20px;
    height: 60px;
  }
  .main-header {
    height: 40px;
    padding: 0 15px 0;
  }
  .font-size-24-19-30-44 {
    font-size: 30px;
  }
  .sudoku-board-grid {
    border: 2px solid #344861;
  }
  .number-btns-container {
    margin-top: 28px;
    display: grid;
    grid-template-columns: repeat(3, 3fr);
    grid-template-rows: repeat(3, 3fr);
    grid-gap: 8px;
    flex: 1;
  }
  .number-btn {
    background: #eaeef4;
    border-radius: 4px;
    color: #385d81;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .number-btn:hover {
    background: #dce3ed;
  }
  .number-btn:active {
    background: #d4ddeb;
  }
  .action-btn {
    width: 60px;
    height: 60px;
  }
  .action-btn > img {
    width: 48px;
  }
}
/* 
@media screen and (max-width: 768px) and (orientation: landscape) {
  .main-header {
    height: 40px;
    padding: 0 15px 0;
  }
  .col-landscape-6 {
    -ms-flex: 0 0 50% !important;
    flex: 0 0 50% !important;
    max-width: 50% !important;
  }
  .pl-landscape-3 {
    padding-left: 1rem !important;
  }
} */
@media screen and (min-width: 992px) {
  .main-header {
    border-bottom: 1px solid rgba(0, 63, 123, 0.1);
    height: 70px;
    padding: 18px 15px 17p;
  }
  .action-btns-container {
    margin-top: 20px;
  }
  .font-size-24-19-30-44 {
    font-size: 44px;
  }
  .action-btn {
    width: 80px;
    height: 80px;
  }
  .action-btn > img {
    width: 48px;
  }
}
.new-game-item {
  cursor: pointer;
  color: #385d81;
  border-radius: 4px;
}
.new-game-item:hover {
  background-color: #385d813d;
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
    -webkit-transform: rotate(359deg);
  }
}

.aspect-ratio-box::before {
  content: "";
  width: 1px;
  margin-left: -1px;
  float: left;
  height: 0;
  padding-top: 100%;
}
.aspect-ratio-box::after {
  /* to clear float */
  content: "";
  display: table;
  clear: both;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

.theme-mode{
  background-color: #eaeef4 ;
  svg{
    fill: #375d81
  }
}
body.dark{
  background-color: #12212e;
  color: white;
  .theme-mode{
    background-color: #375d81;
    svg{
      fill: #ffffff
    }
  }
  .action-btn {
    background: #192b3c;
    color: #eaeef4;
    border: 2px solid #192b3c;
    img{
      fill: white
    }
  }
  
  .sudoku-board-grid {
    border: 2px solid #385d81;
  }
  .timer-pause{
    background-color: #375d81;

  }
  .new-game-item {
    color: white;
  }
  .btn-primary-1 {
    background: #192b3c;
  }
  .card{
    background-color: #192b3c;
    color: white;
  }
  .text-muted{
    color: white !important
  }
  .main-header {
    border-bottom-color:rgba(255, 255, 255, 0.1);
    
  }
  a{
    color: white
  }
}